a {
    color: #00b0aa !important;
}

.social-icon {
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none !important;
    color: inherit !important;
}

hr {
    border-top: 5px white solid !important;
    color: white;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    opacity: 0.5 !important;
}

h2 {
    margin-bottom: 0px !important;
}

.no-select {
    pointer-events: none;
    user-select: none;
}

.App {
    text-align: center;
    background-color: #181a1f;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: calc(10px + 2vmin);
    color: white;

    font-family: "DM Sans";
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-link {
    color: #61dafb;
}

.navbar-nav {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
}

.nav-link {
    color: #fff !important;
    font-weight: bold;
}

nav {
    color: #fff !important;
}
